import React, { Component } from "react";
import { connect } from "react-redux";
import ChartistGraph from "react-chartist";
import * as moment from 'moment'
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { CONFIGS } from '../configs'
import { getAutoP2PPaymentStats, getAutoP2PPayments } from "../actions"
import { _formatCurrency, getDiffSecondsFromNow } from '../utils'
import DashboardChart from "./DashboardChart";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      timeOption: localStorage.getItem('autop2p_time_option') ? localStorage.getItem('autop2p_time_option') : CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.TODAY,
      hourlyPaymentStats: {
        BUY: [],
        SELL: []
      },
      dailyPaymentStats: {
        BUY: [],
        SELL: []
      },
      hourlyPaymentsLatest: {
        BUY: [],
        SELL: []
      },
      dailyPaymentsLatest: {
        BUY: [],
        SELL: []
      },
      totalBuyOrders: 0,
      totalBuyAmount: 0,
      totalSellOrders: 0,
      totalSellAmount: 0,

      lastestBuyOrder: "",
      lastestSellOrder: "",
    };

    this.prefixLog = "XXX Dashboard ";
  }

  componentDidMount() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    var timeMin = today.getTime() - 1000; // 23:59:59 yesterday
    var timeMax = moment().endOf('day').valueOf();
    
    this.props.getAutoP2PPaymentStats({ 
      type: CONFIGS.AUTOP2P_PAYMENT_STAT_TYPES.HOURLY,
      timeMin, timeMax
    });

    var now = new Date();
    var hourNow = new Date(now);
    hourNow.setMinutes(0);
    hourNow.setSeconds(0);

    this.props.getAutoP2PPayments({
      stat: true,
      statType: CONFIGS.AUTOP2P_PAYMENT_STAT_TYPES.HOURLY,
      timeMin: hourNow.getTime(),
      timeMax: now.getTime(),
      limit: 3000
    });

    // DAILY
    timeMin = moment().subtract(91, 'days').valueOf(); // max 90 day
    timeMax = moment().valueOf();

    this.props.getAutoP2PPaymentStats({ 
      type: CONFIGS.AUTOP2P_PAYMENT_STAT_TYPES.DAILY,
      timeMin, timeMax
    });

    var dayNow = new Date(hourNow);
    dayNow.setHours(0);
    dayNow.setMinutes(0);
    dayNow.setSeconds(0);

    this.props.getAutoP2PPayments({ 
      stat: true,
      statType: CONFIGS.AUTOP2P_PAYMENT_STAT_TYPES.DAILY,
      timeMin: dayNow.getTime(),
      timeMax: now.getTime(),
      limit: 3000
    });
  }

  updateTotalStats = () => {
    var { timeOption, hourlyPaymentStats, dailyPaymentStats, hourlyPaymentsLatest, dailyPaymentsLatest } = this.state;
    var totalBuyOrders = 0, 
        totalBuyAmount = 0, 
        totalSellOrders = 0, 
        totalSellAmount = 0;

    // calculate today
    hourlyPaymentStats.BUY.map(stat => { 
      totalBuyOrders += stat.total_orders;
      totalBuyAmount += stat.total_amount;
    });

    hourlyPaymentsLatest.BUY.map(payment => {
      totalBuyOrders ++;
      totalBuyAmount += payment.amount | 0;
    });
    
    hourlyPaymentStats.SELL.map(stat => { 
      totalSellOrders += stat.total_orders;
      totalSellAmount += stat.total_amount | 0;
    });

    hourlyPaymentsLatest.SELL.map(payment => {
      totalSellOrders ++;
      totalSellAmount += payment.amount | 0;
    });

    // calculate other days
    if (timeOption != CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.TODAY) {
      var numberOfDays = 7;

      if (timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_14_DAYS)
        numberOfDays = 14;
      else if (timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_30_DAYS)
        numberOfDays = 30;

      var dailyBuyPaymentStats = dailyPaymentStats.BUY.slice(0, numberOfDays - 1);
      var dailySellPaymentStats = dailyPaymentStats.SELL.slice(0, numberOfDays - 1);

      dailyBuyPaymentStats.map(stat => { 
        totalBuyOrders += stat.total_orders;
        totalBuyAmount += stat.total_amount;
      });

      dailyPaymentsLatest.BUY.map(payment => {
        totalBuyOrders ++;
        totalBuyAmount += payment.amount | 0;
      });
      
      dailySellPaymentStats.map(stat => { 
        totalSellOrders += stat.total_orders;
        totalSellAmount += stat.total_amount;
      });

      dailyPaymentsLatest.SELL.map(payment => {
        totalSellOrders ++;
        totalSellAmount += payment.amount | 0;
      });

      console.log("XXX dailyBuyPaymentStats", dailyBuyPaymentStats, dailySellPaymentStats)
    }

    this.setState({ totalBuyOrders, totalBuyAmount, totalSellOrders, totalSellAmount });
  }

  componentWillReceiveProps(props) {
    var { hourlyPaymentStats, dailyPaymentStats, hourlyPaymentsLatest, dailyPaymentsLatest } = props;

    var lastestBuyOrder = "", lastestSellOrder = "";

    if (hourlyPaymentsLatest && hourlyPaymentsLatest.BUY.length > 0)
      lastestBuyOrder = hourlyPaymentsLatest.BUY[0];
    
    if (hourlyPaymentsLatest.SELL.length > 0)
      lastestSellOrder = hourlyPaymentsLatest.SELL[0];

    this.setState({ 
      hourlyPaymentStats, dailyPaymentStats, 
      hourlyPaymentsLatest, dailyPaymentsLatest,
      lastestBuyOrder, lastestSellOrder
    }, this.updateTotalStats);
  }

  handleSelectTimeOption = (e) => {
    localStorage.setItem('autop2p_time_option', e.target.value);
    this.setState({ timeOption: e.target.value }, this.updateTotalStats);
  }

  renderTimeOptions = () => {
    var { timeOption } = this.state;

    return (
      <div className="mb-3">
        <div className="time-option d-flex flex-column flex-md-row">
          {/* <div className="mr-2"><b>Khoảng thời gian:</b></div> */}
          <Form.Check
            type="radio"
            label="Hôm nay"
            value={CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.TODAY}
            name="group1"
            onChange={this.handleSelectTimeOption}
            checked={timeOption === CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.TODAY}
            className="me-4"
          />

          {/* <Form.Check
            type="radio"
            label="Hôm qua"
            value={CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.YESTERDAY}
            name="group1"
            onChange={this.handleSelectTimeOption}
            checked={timeOption === CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.YESTERDAY}
            className="me-4"
          /> */}

          <Form.Check
            type="radio"
            label="7 ngày"
            value={CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_7_DAYS}
            name="group1"
            onChange={this.handleSelectTimeOption}
            checked={timeOption === CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_7_DAYS}
            className="me-4"
          />

          <Form.Check
            type="radio"
            label="14 ngày"
            value={CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_14_DAYS}
            name="group1"
            onChange={this.handleSelectTimeOption}
            checked={timeOption === CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_14_DAYS}
            className="me-4"
          />

          <Form.Check
            type="radio"
            label="30 ngày"
            value={CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_30_DAYS}
            name="group1"
            onChange={this.handleSelectTimeOption}
            checked={timeOption === CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_30_DAYS}
            className="me-4"
        />
        </div>
      </div>
    )
  }

  render() {
    var { hourlyPaymentStats, dailyPaymentStats, 
      totalBuyOrders, totalBuyAmount, 
      totalSellOrders, totalSellAmount, 
      hourlyPaymentsLatest, dailyPaymentsLatest,
      lastestBuyOrder, lastestSellOrder } = this.state;

    console.log(this.prefixLog + "render", this.state);

    return (
      <>
        <Container fluid>
          <Row>
            {this.renderTimeOptions()}
          </Row>
          <Row>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-cart-simple text-success"></i>
                      </div>
                    </Col>
                    <Col xs="9">
                      <div className="numbers">
                        <p className="card-category">Tổng Lệnh Mua</p>
                        <Card.Title as="h4"><span className="text-green">{totalBuyOrders}</span></Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="fas fa-redo mr-1"></i>
                    {lastestBuyOrder && getDiffSecondsFromNow(lastestBuyOrder.created_at)}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-cart-simple text-success"></i>
                      </div>
                    </Col>
                    <Col xs="9">
                      <div className="numbers">
                        <p className="card-category">Tổng Tiền Mua</p>
                        <Card.Title as="h4"><span className="text-green">{_formatCurrency(totalBuyAmount)}</span></Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="fas fa-redo mr-1"></i>
                    {lastestBuyOrder && getDiffSecondsFromNow(lastestBuyOrder.created_at)}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-danger"></i>
                      </div>
                    </Col>
                    <Col xs="9">
                      <div className="numbers">
                        <p className="card-category">Tổng Lệnh Bán</p>
                        <Card.Title as="h4"><span className="text-red">{totalSellOrders}</span></Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="fas fa-redo mr-1"></i>
                    {lastestSellOrder && getDiffSecondsFromNow(lastestSellOrder.created_at)}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-danger"></i>
                      </div>
                    </Col>
                    <Col xs="9">
                      <div className="numbers">
                        <p className="card-category">Tổng Tiền Bán</p>
                        <Card.Title as="h4"><span className="text-red">{_formatCurrency(totalSellAmount)}</span></Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="fas fa-exclamation-triangle mr-1"></i>
                    Tool chưa hỗ trợ
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          
          <Row className="mb-1">
            <Col md="6">
              <DashboardChart 
                hourlyPaymentStats={hourlyPaymentStats.BUY}
                dailyPaymentStats={dailyPaymentStats.BUY}
                hourlyPaymentsLatest = {hourlyPaymentsLatest.BUY}
                dailyPaymentsLatest = {dailyPaymentsLatest.BUY}
                paymentType="BUY"
                dataType="ORDER"
                timeOption={this.state.timeOption}
                total={totalBuyOrders}
              />
            </Col>

            <Col md="6">
              <DashboardChart 
                hourlyPaymentStats={hourlyPaymentStats.SELL}
                dailyPaymentStats={dailyPaymentStats.SELL}
                hourlyPaymentsLatest = {hourlyPaymentsLatest.SELL}
                dailyPaymentsLatest = {dailyPaymentsLatest.SELL}
                paymentType="SELL"
                dataType="ORDER"
                timeOption={this.state.timeOption}
                total={totalSellOrders}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="6">
              <DashboardChart 
                hourlyPaymentStats={hourlyPaymentStats.BUY}
                dailyPaymentStats={dailyPaymentStats.BUY}
                hourlyPaymentsLatest = {hourlyPaymentsLatest.BUY}
                dailyPaymentsLatest = {dailyPaymentsLatest.BUY}
                paymentType="BUY"
                dataType="AMOUNT"
                timeOption={this.state.timeOption}
                total={_formatCurrency(totalBuyAmount)}
              />
            </Col>

            <Col md="6">
              <DashboardChart 
                hourlyPaymentStats={hourlyPaymentStats.SELL}
                dailyPaymentStats={dailyPaymentStats.SELL}
                hourlyPaymentsLatest = {hourlyPaymentsLatest.SELL}
                dailyPaymentsLatest = {dailyPaymentsLatest.SELL}
                paymentType="SELL"
                dataType="AMOUNT"
                timeOption={this.state.timeOption}
                total={_formatCurrency(totalSellAmount)}
              />
            </Col>
          </Row>
          
          {/* <Row>
            <Col md="8">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Total Buy</Card.Title>
                  <p className="card-category">24 Hours performance</p>
                </Card.Header>
                <Card.Body>
                  <div className="ct-chart" id="chartHours">
                    <ChartistGraph
                      data={{
                        labels: [
                          "9:00AM",
                          "12:00AM",
                          "3:00PM",
                          "6:00PM",
                          "9:00PM",
                          "12:00PM",
                          "3:00AM",
                          "6:00AM",
                        ],
                        series: [
                          [287, 385, 490, 492, 554, 586, 698, 695],
                          [67, 152, 143, 240, 287, 335, 435, 437],
                          [23, 113, 67, 108, 190, 239, 307, 308],
                        ],
                      }}
                      type="Line"
                      options={{
                        low: 0,
                        high: 800,
                        showArea: false,
                        height: "245px",
                        axisX: {
                          showGrid: false,
                        },
                        lineSmooth: true,
                        showLine: true,
                        showPoint: true,
                        fullWidth: true,
                        chartPadding: {
                          right: 50,
                        },
                      }}
                      responsiveOptions={[
                        [
                          "screen and (max-width: 640px)",
                          {
                            axisX: {
                              labelInterpolationFnc: function (value) {
                                return value[0];
                              },
                            },
                          },
                        ],
                      ]}
                    />
                  </div>
                </Card.Body>
                <Card.Footer>
                  <div className="legend">
                    <i className="fas fa-circle text-info"></i>
                    Open <i className="fas fa-circle text-danger"></i>
                    Click <i className="fas fa-circle text-warning"></i>
                    Click Second Time
                  </div>
                  <hr></hr>
                  <div className="stats">
                    <i className="fas fa-history"></i>
                    Updated 3 minutes ago
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Email Statistics</Card.Title>
                  <p className="card-category">Last Campaign Performance</p>
                </Card.Header>
                <Card.Body>
                  <div
                    className="ct-chart ct-perfect-fourth"
                    id="chartPreferences"
                  >
                    <ChartistGraph
                      data={{
                        labels: ["40%", "20%", "40%"],
                        series: [40, 20, 40],
                      }}
                      type="Pie"
                    />
                  </div>
                  <div className="legend">
                    <i className="fas fa-circle text-info"></i>
                    Open <i className="fas fa-circle text-danger"></i>
                    Bounce <i className="fas fa-circle text-warning"></i>
                    Unsubscribe
                  </div>
                  <hr></hr>
                  <div className="stats">
                    <i className="far fa-clock"></i>
                    Campaign sent 2 days ago
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">2017 Sales</Card.Title>
                  <p className="card-category">All products including Taxes</p>
                </Card.Header>
                <Card.Body>
                  <div className="ct-chart" id="chartActivity">
                    <ChartistGraph
                      data={{
                        labels: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "Mai",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ],
                        series: [
                          [
                            542,
                            443,
                            320,
                            780,
                            553,
                            453,
                            326,
                            434,
                            568,
                            610,
                            756,
                            895,
                          ],
                          [
                            412,
                            243,
                            280,
                            580,
                            453,
                            353,
                            300,
                            364,
                            368,
                            410,
                            636,
                            695,
                          ],
                        ],
                      }}
                      type="Bar"
                      options={{
                        seriesBarDistance: 10,
                        axisX: {
                          showGrid: false,
                        },
                        height: "245px",
                      }}
                      responsiveOptions={[
                        [
                          "screen and (max-width: 640px)",
                          {
                            seriesBarDistance: 5,
                            axisX: {
                              labelInterpolationFnc: function (value) {
                                return value[0];
                              },
                            },
                          },
                        ],
                      ]}
                    />
                  </div>
                </Card.Body>
                <Card.Footer>
                  <div className="legend">
                    <i className="fas fa-circle text-info"></i>
                    Tesla Model S <i className="fas fa-circle text-danger"></i>
                    BMW 5 Series
                  </div>
                  <hr></hr>
                  <div className="stats">
                    <i className="fas fa-check"></i>
                    Data information certified
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-tasks">
                <Card.Header>
                  <Card.Title as="h4">Tasks</Card.Title>
                  <p className="card-category">Backend development</p>
                </Card.Header>
                <Card.Body>
                  <div className="table-full-width">
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <Form.Check className="mb-1 pl-0">
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultValue=""
                                  type="checkbox"
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                              </Form.Check.Label>
                            </Form.Check>
                          </td>
                          <td>
                            Sign contract for "What are conference organizers
                            afraid of?"
                          </td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-488980961">
                                  Edit Task..
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="info"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-506045838">Remove..</Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Form.Check className="mb-1 pl-0">
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultChecked
                                  defaultValue=""
                                  type="checkbox"
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                              </Form.Check.Label>
                            </Form.Check>
                          </td>
                          <td>
                            Lines From Great Russian Literature? Or E-mails From
                            My Boss?
                          </td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-537440761">
                                  Edit Task..
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="info"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-21130535">Remove..</Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Form.Check className="mb-1 pl-0">
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultChecked
                                  defaultValue=""
                                  type="checkbox"
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                              </Form.Check.Label>
                            </Form.Check>
                          </td>
                          <td>
                            Flooded: One year later, assessing what was lost and
                            what was found when a ravaging rain swept through
                            metro Detroit
                          </td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-577232198">
                                  Edit Task..
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="info"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-773861645">Remove..</Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Form.Check className="mb-1 pl-0">
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultChecked
                                  type="checkbox"
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                              </Form.Check.Label>
                            </Form.Check>
                          </td>
                          <td>
                            Create 4 Invisible User Experiences you Never Knew
                            About
                          </td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-422471719">
                                  Edit Task..
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="info"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-829164576">Remove..</Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Form.Check className="mb-1 pl-0">
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultValue=""
                                  type="checkbox"
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                              </Form.Check.Label>
                            </Form.Check>
                          </td>
                          <td>Read "Following makes Medium better"</td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-160575228">
                                  Edit Task..
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="info"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-922981635">Remove..</Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Form.Check className="mb-1 pl-0">
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultValue=""
                                  disabled
                                  type="checkbox"
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                              </Form.Check.Label>
                            </Form.Check>
                          </td>
                          <td>Unfollow 5 enemies from twitter</td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-938342127">
                                  Edit Task..
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="info"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-119603706">Remove..</Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="now-ui-icons loader_refresh spin"></i>
                    Updated 3 minutes ago
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hourlyPaymentStats: state.autoP2PReducer.autoP2PPaymentStats.HOURLY,
    dailyPaymentStats: state.autoP2PReducer.autoP2PPaymentStats.DAILY,
    hourlyPaymentsLatest: state.autoP2PReducer.autoP2PPaymentsLatest.HOURLY,
    dailyPaymentsLatest: state.autoP2PReducer.autoP2PPaymentsLatest.DAILY,
    timestampGetStat: state.autoP2PReducer.timestampGetStat
  }
}

export default connect(mapStateToProps, {
  getAutoP2PPaymentStats,
  getAutoP2PPayments
})(Dashboard)
