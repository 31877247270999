/* eslint-disable no-undef */
import React from "react";

// {getTranslate(LANGUAGE_KEYS.TEXT_BOT_ID)}

const LANGUAGES = {
    "TEXT_TITLE": [process.env.REACT_APP_TITLE, process.env.REACT_APP_TITLE],
    "TEXT_GREETING": [`Hello [@]. Today is [@]`, `Xin chào [@]. Hôm nay là [@]`],
    "TEXT_BOT_ID": [`Bot ID`, `Bot ID`],
    "TEXT_JOB_ID": [`Job ID`, `Công việc`],
    "TEXT_ALL": [`All`, `Tất cả`],
    "TEXT_TASK": [`Task`, `Tác vụ`],
    "TEXT_FROM": [`From`, `Từ ngày`],
    "TEXT_SEARCH": [`Search`, `Tìm kiếm`],
    "TEXT_DAILY_VIEW": [`Daily View`, `Lượt xem hàng ngày`],
    "TEXT_PRICE_MIN": [`Price Min`, `Giá tối thiểu`],
    "TEXT_PRICE_MAX": [`Price Max`, `Giá tối đa`],
    "TEXT_SOLD_30_CALCULATE": [`Sold-30 Calculate`, `Ước tính bán 30 ngày`],
    "TEXT_REVENUE_30_CALCULATE": [`Revenue-30 Calculate`, `Ước tính doanh thu 30 ngày`],
    "TEXT_SOLD_30_COUNT": [`Sold-30 Count`, `Thực tế bán 30 ngày`],
    "TEXT_REVENUE_30_COUNT": [`Revenue-30 Count`, `Thực tế doanh thu 30 ngày`],
    "TEXT_FAVORITE": [`Favorite`, `Yêu thích`],
    "TEXT_FAVORITE_RATE": [`Favorite rate`, `Tỉ lệ yêu thích`],
    "TEXT_LAST_UPDATE": [`Last Update`, `Cập nhật cuối`],
    "TEXT_SORT_BY": [`Sort by`, `Sắp xếp theo`],
    "TEXT_SORT_ORDER": [`Sort order`, `Thứ tự sắp xếp`],
    "TEXT_PRICE": [`Price`, `Giá`],
    "TEXT_TOTAL_VIEWS": [`Total Views`, `Tổng lượt xem`],
    "TEXT_CREATED_DATE": [`Created date`, `Ngày tạo`],
    "TEXT_EIT_CREATE": [`EIT create`, `Ngày tạo EIT`],
    "TEXT_EIT_UPDATE": [`EIT update`, `Ngày update EIT`],
    "TEXT_LAST_CHECK_HEY": [`Last Check Hey`, `Cập nhật Hey`],
    "TEXT_FOUND_X_LISTINGS": [`Found [@] listings`, `Tìm thấy [@] sản phẩm`],
    "TEXT_FLOATING_SEARCH_FILTER": [`Floating Search Filter`, `Hiển thị Thanh tìm kiếm nổi`],
    "TEXT_PREVIOUS": [`Previous`, `Trước`],
    "TEXT_NEXT": [`Next`, `Sau`],
    "TEXT_DAY": [`day`, `ngày`],
    "TEXT_DAYS": [`days`, `ngày`],
    "TEXT_WEEK": [`week`, `tuần`],
    "TEXT_WEEKS": [`weeks`, `tuần`],
    "TEXT_MONTH": [`month`, `tháng`],
    "TEXT_MONTHS": [`months`, `tháng`],
    "TEXT_DECREASE": [`Decrease`, `Giảm dần`],
    "TEXT_INCREASE": [`Increase`, `Tăng dần`],
    "TEXT_CREATED": [`Created`, `Ngày tạo`],
    "TEXT_SEARCH_AND_FILTER": [`Search & Filter`, `Tìm kiếm & Lọc`],
    "TEXT_COMPETITOR_ANALYSIS": [`Competitor Analysis`, `Phân tích đối thủ`],
    "TEXT_VISIT_SHOP_X": [`Visit shop [@]`, `Xem shop [@]`],
    "TEXT_LISTING": [`Listing`, `Sản phẩm`],
    "TEXT_COLLECTION_DETAIL": [`Collection Detail`, `Chi tiết bộ sưu tập`],
    "TEXT_COLLECTION": [`Collection`, `Bộ sưu tập`],
    "TEXT_SHOP": [`Shop`, `Cửa hàng`],
    "TEXT_BOT": [`Bot`, `Bot`],
    "TEXT_3D_TOOL": [`3D Tool`, `Tool 3D`],
    "TEXT_BOX_MAKER": [`Box Maker`, `Vẽ Hộp`],
    "TEXT_DESIGN_LAMP_TOOL": [`Design Lamp Tool`, `Tool thiết kế đèn`],
    "TEXT_DASHBOARD": [`Dashboard`, `Dashboard`],
    "TEXT_USER_PROFILE": [`User Profile`, `Người dùng`],
    "TEXT_ENTER_KEYWORD": [`Enter keyword`, `Nhập từ khóa`],
    "TEXT_RUNNING_TASKS": [`Running Tasks`, `Tác vụ đang chạy`],
    "TEXT_AUTOMATICALLY_UPDATE_IN_X_SECONDS": [`Automatically update in [@] seconds`, `Tự động cập nhật sau [@] giây`],
    "TEXT_JOB": [`Job`, `Công việc`],
    "TEXT_STEP": [`Step`, `Bước`],
    "TEXT_STATUS": [`Status`, `Trạng thái`],
    "TEXT_PROGRESS": [`Progress`, `Tiến độ`],
    "TEXT_RUNNING_TIME": [`Running time`, `Thời gian chạy`],
    "TEXT_LAST_ACTIVITY": [`Last activity`, `Hoạt động lần cuối`],
    "TEXT_ACTION": [`Action`, `Hoạt động`],
    "TEXT_ASSIGN_JOB": [`Assign Job`, `Giao Việc`],
    "TEXT_X_HOURS_Y_MINUTES": [`[@] hours [@] minutes`, `[@] giờ [@] phút`],
    "TEXT_X_MINUTES_AGO": [`[@] minutes ago`, `[@] phút trước`],
    "TEXT_A_FEW_SECONDS_AGO": [`a few seconds ago`, `một vài giây trước`],
    "TEXT_X_HOURS_AGO": [`[@] hours ago`, `[@] giờ trước`],
    "TEXT_PING_TIME": [`Ping time`, `Thời gian ping`],
    "TEXT_CHECKIN_TIME": [`Checkin time`, `Thời gian checkin`],
    "TEXT_SCHEDULED_TASKS": [`Scheduled Tasks`, `Tác Vụ Lên Lịch`],
    "TEXT_SEARCH_KEYWORD": [`Search Keyword`, `Tìm Theo Từ Khóa`],
    "TEXT_SEARCH_TAG": [`Search Tag`, `Tìm Theo Tag`],
    "TEXT_SEARCH_BY_CATEGORY": [`Search By Category`, `Tìm Theo Danh Mục`],
    "TEXT_LISTINGS___SHOPS": [`Listings / Shops`, `Phân tích Sản phẩm / Cửa hàng`],
    "TEXT_TOTAL_SALES": ["Total sold", 'Tổng đơn'],
    "TEXT_SHOP_SOLD_30_DAYS": ["Sold 30 days", "Đơn 30 ngày"],
    "TEXT_SHOP_SOLD_90_DAYS": ["Sold 90 days", "Đơn 90 ngày"],
    "TEXT_RATING": ["Rating", "Điểm đánh giá"],
    "TEXT_OPENING_DATE": ["Opening Date", "Ngày mở shop"],
    "TEXT_FOUND_X_SHOPS": [`Found [@] shops`, `Tìm thấy [@] cửa hàng`],
    "TEXT_MIN": ["Min", "Nhỏ nhất"],
    "TEXT_MAX": ["Max", "Lớn nhất"],
    "TEXT_LAST_SOLD_DAYS": ["Last sold days", "Đơn gần nhất"],
    "TEXT_TOTAL_LISTINGS": ["Total listings", "Tổng số sản phẩm"],
    "TEXT_HOUR": ["hour", "giờ"],
    "TEXT_SOLD_CHART": ["Sold Chart", "Biển Đồ Sold"]
}

export const LANGUAGE_KEYS = {
    "TEXT_TITLE": "TEXT_TITLE",
    "TEXT_GREETING": `TEXT_GREETING`,
    "TEXT_BOT_ID": `TEXT_BOT_ID`,
    "TEXT_JOB_ID": `TEXT_JOB_ID`,
    "TEXT_ALL": `TEXT_ALL`,
    "TEXT_TASK": `TEXT_TASK`,
    "TEXT_FROM": `TEXT_FROM`,
    "TEXT_SEARCH": `TEXT_SEARCH`,
    "TEXT_DAILY_VIEW": `TEXT_DAILY_VIEW`,
    "TEXT_PRICE_MIN": `TEXT_PRICE_MIN`,
    "TEXT_PRICE_MAX": `TEXT_PRICE_MAX`,
    "TEXT_SOLD_30_CALCULATE": `TEXT_SOLD_30_CALCULATE`,
    "TEXT_REVENUE_30_CALCULATE": `TEXT_REVENUE_30_CALCULATE`,
    "TEXT_SOLD_30_COUNT": `TEXT_SOLD_30_COUNT`,
    "TEXT_REVENUE_30_COUNT": `TEXT_REVENUE_30_COUNT`,
    "TEXT_FAVORITE": `TEXT_FAVORITE`,
    "TEXT_FAVORITE_RATE": `TEXT_FAVORITE_RATE`,
    "TEXT_LAST_UPDATE": `TEXT_LAST_UPDATE`,
    "TEXT_SORT_BY": `TEXT_SORT_BY`,
    "TEXT_SORT_ORDER": `TEXT_SORT_ORDER`,
    "TEXT_PRICE": `TEXT_PRICE`,
    "TEXT_TOTAL_VIEWS": `TEXT_TOTAL_VIEWS`,
    "TEXT_CREATED_DATE": `TEXT_CREATED_DATE`,
    "TEXT_EIT_CREATE": `TEXT_EIT_CREATE`,
    "TEXT_EIT_UPDATE": `TEXT_EIT_UPDATE`,
    "TEXT_LAST_CHECK_HEY": `TEXT_LAST_CHECK_HEY`,
    "TEXT_FOUND_X_LISTINGS": `TEXT_FOUND_X_LISTINGS`,
    "TEXT_FLOATING_SEARCH_FILTER": `TEXT_FLOATING_SEARCH_FILTER`,
    "TEXT_PREVIOUS": `TEXT_PREVIOUS`,
    "TEXT_NEXT": `TEXT_NEXT`,
    "TEXT_DAY": `TEXT_DAY`,
    "TEXT_DAYS": `TEXT_DAYS`,
    "TEXT_WEEK": `TEXT_WEEK`,
    "TEXT_WEEKS": `TEXT_WEEKS`,
    "TEXT_MONTH": `TEXT_MONTH`,
    "TEXT_MONTHS": `TEXT_MONTHS`,
    "TEXT_DECREASE": `TEXT_DECREASE`,
    "TEXT_INCREASE": `TEXT_INCREASE`,
    "TEXT_CREATED": `TEXT_CREATED`,
    "TEXT_SEARCH_AND_FILTER": `TEXT_SEARCH_AND_FILTER`,
    "TEXT_COMPETITOR_ANALYSIS": `TEXT_COMPETITOR_ANALYSIS`,
    "TEXT_VISIT_SHOP_X": `TEXT_VISIT_SHOP_X`,
    "TEXT_LISTING": `TEXT_LISTING`,
    "TEXT_COLLECTION_DETAIL": `TEXT_COLLECTION_DETAIL`,
    "TEXT_COLLECTION": `TEXT_COLLECTION`,
    "TEXT_SHOP": `TEXT_SHOP`,
    "TEXT_BOT": `TEXT_BOT`,
    "TEXT_3D_TOOL": `TEXT_3D_TOOL`,
    "TEXT_BOX_MAKER": "TEXT_BOX_MAKER",
    "TEXT_DESIGN_LAMP_TOOL": `TEXT_DESIGN_LAMP_TOOL`,
    "TEXT_DASHBOARD": `TEXT_DASHBOARD`,
    "TEXT_USER_PROFILE": `TEXT_USER_PROFILE`,
    "TEXT_ENTER_KEYWORD": `TEXT_ENTER_KEYWORD`,
    "TEXT_RUNNING_TASKS": `TEXT_RUNNING_TASKS`,
    "TEXT_AUTOMATICALLY_UPDATE_IN_X_SECONDS": `TEXT_AUTOMATICALLY_UPDATE_IN_X_SECONDS`,
    "TEXT_JOB": `TEXT_JOB`,
    "TEXT_STEP": `TEXT_STEP`,
    "TEXT_STATUS": `TEXT_STATUS`,
    "TEXT_PROGRESS": `TEXT_PROGRESS`,
    "TEXT_RUNNING_TIME": `TEXT_RUNNING_TIME`,
    "TEXT_LAST_ACTIVITY": `TEXT_LAST_ACTIVITY`,
    "TEXT_ACTION": `TEXT_ACTION`,
    "TEXT_ASSIGN_JOB": `TEXT_ASSIGN_JOB`,
    "TEXT_X_HOURS_Y_MINUTES": `TEXT_X_HOURS_Y_MINUTES`,
    "TEXT_X_MINUTES_AGO": `TEXT_X_MINUTES_AGO`,
    "TEXT_A_FEW_SECONDS_AGO": `TEXT_A_FEW_SECONDS_AGO`,
    "TEXT_X_HOURS_AGO": `TEXT_X_HOURS_AGO`,
    "TEXT_PING_TIME": `TEXT_PING_TIME`,
    "TEXT_CHECKIN_TIME": `TEXT_CHECKIN_TIME`,
    "TEXT_SCHEDULED_TASKS": `TEXT_SCHEDULED_TASKS`,
    "TEXT_SEARCH_KEYWORD": `TEXT_SEARCH_KEYWORD`,
    "TEXT_SEARCH_TAG": `TEXT_SEARCH_TAG`,
    "TEXT_SEARCH_BY_CATEGORY": `TEXT_SEARCH_BY_CATEGORY`,
    "TEXT_LISTINGS___SHOPS": `TEXT_LISTINGS___SHOPS`,
    "TEXT_TOTAL_SALES": "TEXT_TOTAL_SALES",
    "TEXT_SHOP_SOLD_30_DAYS": "TEXT_SHOP_SOLD_30_DAYS",
    "TEXT_SHOP_SOLD_90_DAYS": "TEXT_SHOP_SOLD_90_DAYS",
    "TEXT_RATING": "TEXT_RATING",
    "TEXT_OPENING_DATE": "TEXT_OPENING_DATE",
    "TEXT_FOUND_X_SHOPS": `TEXT_FOUND_X_SHOPS`,
    "TEXT_LAST_SOLD_DAYS": "TEXT_LAST_SOLD_DAYS",
    "TEXT_TOTAL_LISTINGS": "TEXT_TOTAL_LISTINGS",
    "TEXT_HOUR": "TEXT_HOUR",
    "TEXT_SOLD_CHART": "TEXT_SOLD_CHART"
};

const LANGUAGE_CODES = {
  EN: 0,
  VN: 1
};

export const getCurrentLanguage = () => {
  let currentLanguage = window.localStorage.getItem("eit_language");
  if (!currentLanguage || ["EN", "VN"].indexOf(currentLanguage) === 1) currentLanguage = "VN";

  return currentLanguage.toLowerCase();
};
export const getCurrentLanguageForMoment = () => {
  let currentLanguage = window.localStorage.getItem("eit_language");
  if (!currentLanguage || ["EN", "VN"].indexOf(currentLanguage) === -1) currentLanguage = "VN";
  return currentLanguage.toLowerCase();
};

export const getTranslate = (key, components) => {
  if (!LANGUAGE_KEYS[key]) return key;

  let currentLanguage = window.localStorage.getItem("eit_language");
  if (!currentLanguage || ["EN", "VN"].indexOf(currentLanguage) === -1) currentLanguage = "VN";

  const langCode = LANGUAGE_CODES[currentLanguage];

  if (!components) return LANGUAGES[key][langCode];

  const keys = LANGUAGES[key][langCode].split("[@]");
  // console.log("language @", keys);
  const output = (
    <>
      {keys.map((k, i) => (
        <>
          {k}
          {components[i]}
        </>
      ))}
    </>
  );

  return output;
};

export const getCustomTranslate = (prefix, text, components) => {
  if (!text) return "";

  const modifyText = text.replace("-", "_").replace("(", "").replace(")", "");
  const keyOnly = prefix
    ? [
        prefix,
        modifyText
          .split(" ")
          .filter((v) => v)
          .join("_")
          .toUpperCase(),
      ].join("_")
    : modifyText
        .split(" ")
        .filter((v) => v)
        .join("_")
        .toUpperCase();

  const key = ["TEXT", keyOnly].join("_");

  let tmp = window.localStorage.getItem(`LANG_${prefix}`);

  if (!tmp) tmp = "";

  const line = `${keyOnly}\t${text}\n`;

  if (!tmp.includes(line)) tmp += line;

  window.localStorage.setItem(`LANG_${prefix}`, tmp);

  return getTranslate(key, components);
};
