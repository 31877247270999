/* eslint-disable func-names */
import React, { Component, createRef  } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { setFlag, getShopSoldStat, getShopSold, getLatestShopSold } from '../actions';
import { CONFIGS } from '../configs';
import { ToastContainer, toast } from 'react-toastify';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Table
  } from "react-bootstrap";

import { FaChartPie } from "react-icons/fa";
import socket from '../socket';

import moment from "moment";
import { _formatCurrency } from '../utils'

class DashboardChart extends Component {
  constructor() {
    super();
    this.chartRef = createRef();
    this.state = {
      chartData: [],
      timeOption:"",
      paymentType: "",
      dataType: "",
      hourlyPaymentStats: [],
      dailyPaymentStats: [],
      hourlyPaymentsLatest: [],
      dailyPaymentsLatest: [],
      total: ""
    };

    this.prefixLog = "XXX DashboardChart ";
  }

  componentDidMount() {
    // this.fetchData(this.props.viewMode, this.props.shopTeam);
    
    // this.interval = setInterval(() => {
    //   this.updateHighlight();
    // }, 900);
  }

  renderOptions = () => {
    const { timeOption, paymentType, dataType, hourlyPaymentStats, dailyPaymentStats, total, hourlyPaymentsLatest, dailyPaymentsLatest } = this.state;
    var chartName = "";

    var data = [];

    var typeText = paymentType == "BUY" ? "Mua": "Bán";
    var typeColor = paymentType == "BUY" ? "#28a745": "red";
    var dataTypeText = dataType == "ORDER" ? "Lệnh": "Tiền";

    if (timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.TODAY) {
      const currentDate = moment().format('DD/MM');
      chartName = `Tổng ${dataTypeText} ${typeText} Ngày ${currentDate}`;

      var data = hourlyPaymentStats.map(stat => {
        // totalSold += soldData.delta_sold;
        const date = new Date(stat.timestamp);
  
        // Lấy giờ từ đối tượng Date
        // const hours = date.getHours(); 
        // date.setMinutes(0);
        // date.setSeconds(0);
        // date.setMilliseconds(0);
  
        return [date.getTime(), dataType == "ORDER" ? stat.total_orders: stat.total_amount]
      });

      var totalHourlyLatestAmount = 0;
      hourlyPaymentsLatest.map(payment => totalHourlyLatestAmount += payment.amount | 0);

      console.log(this.prefixLog + "totalHourlyLatestAmount", totalHourlyLatestAmount);
  
      var hourNow = new Date();
      hourNow.setMinutes(0);
      hourNow.setSeconds(0);

      data.push([hourNow.getTime(), dataType == "ORDER"? hourlyPaymentsLatest.length: totalHourlyLatestAmount])
  
      var now = new Date();
  
      for (var i = data.length; i<23; i++) {
        let date = new Date(now.getTime());

        // Lấy giờ từ đối tượng Date
        date.setHours(i, 0, 0, 0); 
  
        data.push([date.getTime(), 0])
      }
    } else if (timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_7_DAYS
      || timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_14_DAYS
      || timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_30_DAYS
    ) {
      var numberOfDays = 0;

      if (timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_7_DAYS)
        numberOfDays = 7;
      else if (timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_14_DAYS)
        numberOfDays = 14;      
      else if (timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_30_DAYS)
          numberOfDays = 30;

      var currentDailyPaymentStats = dailyPaymentStats.slice(0, numberOfDays - 1).reverse();

      const fromDay = moment().subtract(numberOfDays - 1, 'days').format('DD/MM');
      const today = moment().format('DD/MM');

      chartName = `Tổng ${dataTypeText} ${typeText} từ ngày ${fromDay} đến ${today}`;
  
      if (currentDailyPaymentStats.length > 1)
        for (var i = numberOfDays - 1; i >= currentDailyPaymentStats.length; i--) {
          const time = moment().subtract(i, 'days') // Trừ i ngày
          .startOf('day')      // Đặt về 0 giờ, 0 phút, 0 giây
          .valueOf();
    
          data.push([time, 0])
        }

      var startIndex = Math.max(currentDailyPaymentStats.length - numberOfDays + 1, 1);

      for (var i=startIndex; i<currentDailyPaymentStats.length; i++) {
        const date = new Date(currentDailyPaymentStats[i].timestamp);

        data.push([date.getTime(), dataType == "ORDER" ? 
          currentDailyPaymentStats[i].total_orders: 
          currentDailyPaymentStats[i].total_amount]);
      }

      var totaDailyLatestAmount = 0;
      dailyPaymentsLatest.map(payment => totaDailyLatestAmount += payment.amount | 0);
  
      var dayNow = new Date();
      dayNow.setHours(0, 0, 0, 0);

      data.push([dayNow.getTime(), dataType == "ORDER"? dailyPaymentsLatest.length: totaDailyLatestAmount]);
      
      // if (data.length < numberOfDays - 1) {
      //   const now = new Date();
      //   now.setHours(0, 0, 0, 0);

      //   data.push([now.getTime(), 0])
      // }
    }

    this.chartData = data;
    console.log(this.prefixLog + "data", data);

    return {
      time: {
        timezoneOffset: -7 * 60 // GMT+7 (7 giờ so với GMT, mỗi giờ có 60 phút)
      },
      chart: {
        type: 'column'
      },
      title: {
        text: `<span style="color: ${typeColor};" class="text-uppercase">TỔNG ${dataTypeText} ${typeText} (${total})</span>`,
        // text: `Đơn: </span><span style="color: red;">${totalSold}</span>`,
        useHTML: true, // Bật tính năng HTML cho title
      },
      xAxis: {
        type: 'datetime', // Đảm bảo rằng trục X là thời gian
        title: {
            // text: 'Giờ'
        },
        labels: {
          formatter: function() {
              // Lấy giờ từ timestamp
              let date = new Date(this.value);
              if (timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.TODAY)
                return date.getHours() + "h";  // Chỉ lấy giờ và thêm "h"
              else if (timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_7_DAYS
                || timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_14_DAYS
                || timeOption == CONFIGS.AUTOP2P_DASHBOARD_TIME_OPTIONS.LAST_30_DAYS
              ) {
                return moment(date).format("DD/MM");
              }
          }
        }
      },
      yAxis: {
        // min: 0,
        // max: yMax,
        title: {
          text: 'Sold'
        },
        // labels: {
        //   formatter: function () {
        //       return _formatCurrency(this.value); // Format giá trị trên trục Y
        //   }
        // }
      },
      series: [{
        data: data.map((item, index) => ({
          x: item[0],
          y: item[1], //#2CAFFE
          color: typeColor
        })),
        name: chartName
        // name: `<span style="color: black; font-size: 20px; font-weight: bold">${this.state.shopName}`,
        // colorByPoint: true
      }],
      plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                crop: false,
                overflow: 'none',
                formatter: function () {
                  if (dataType == "ORDER")
                    return this.y;

                  return _formatCurrency(this.y); // Format giá trị hiển thị trên cột
                }
            }
        }
      },
    }
  }
  

  componentWillReceiveProps(props) {
    var { paymentType, dataType, timeOption, hourlyPaymentStats, dailyPaymentStats, total, hourlyPaymentsLatest, dailyPaymentsLatest } = props;

    console.log(this.prefixLog + "props", props);

    this.setState({ paymentType, dataType, timeOption, hourlyPaymentStats, dailyPaymentStats, total, hourlyPaymentsLatest, dailyPaymentsLatest })
  } 

  render() {
    var { timeOption, hourlyPaymentStats, dailyPaymentStats } = this.state;
    console.log(this.prefixLog + "hourlyPaymentStats", hourlyPaymentStats);

    return <>        
        {dailyPaymentStats && dailyPaymentStats.length > 0 ?
        <HighchartsReact
          highcharts={Highcharts}
          options={this.renderOptions()}
          ref={this.chartRef}
        />: <div class="loading">
        <FerrisWheelSpinner loading={true}　color="#17a2b8" />
      </div>}
      </>
  }
}

const mapStateToProps = (state) => {
  return {
    // soldData: state.shopSoldReducer.soldData
  };
};

export default connect(mapStateToProps, { })(DashboardChart)