/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";
import { NullOrEmpty } from "../utils"

export const getAutoP2PPayments = (query) => {
  var {
    paymentType,
    paymentFilter,
    timeMin,
    timeMax,
    limit
  } = query;

  var sortBy = "-_id";
  var status = '';

  if (paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NOT_PAID) {
    sortBy = "timestamp_step2";
    status = CONFIGS.AUTOP2P_PAYMENT_STATUS.NEW_2;
  } else if (paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.CONFIRM_PAID) {
    // status = CONFIGS.AUTOP2P_PAYMENT_STATUS.CON;
    sortBy = "-timestamp_step3";
  }

  var strQuery = "";
  
  if (paymentType)
    `type=${paymentType}`;

  if (status)
    strQuery += `&status=${status}`;

  if (timeMin)
    strQuery += `&timestamp_step1>=${timeMin}`;

  if (timeMax)
    strQuery += `&timestamp_step1<${timeMax}`;

  if (limit)
    strQuery += `&limit=${timeMax}`

  // strQuery += `&tmp=${Date.now()}&sort=-amount&limit=100`;
  strQuery += `&tmp=${Date.now()}&sort=${sortBy}`;

  return API.get(`${process.env.REACT_APP_API_URL}/api/autop2p/get?${strQuery}`);
}

export const getAutoP2PPaymentStats = (query) => {
  var {
    type,
    timeMin,
    timeMax
  } = query;

  var sortBy = "-_id";

  var strQuery = `type=${type}`;

  if (timeMin)
    strQuery += `&timestamp>=${timeMin}`

  if (timeMax)
    strQuery += `&timestamp<${timeMax}`

  strQuery += `&tmp=${Date.now()}&sort=${sortBy}`;

  return API.get(`${process.env.REACT_APP_API_URL}/api/autop2p/get-stats?${strQuery}`);
}

export const updateAutoP2PPayments = (payments) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/autop2p`, payments);
}

export const getLoadingP2POptionUID = (uid) => {
  return API.get(`${process.env.REACT_APP_API_URL}/api/json-data/get?key=AUTOP2P_LOADING_P2P_OPTION_${uid}`);
}

export const updateLoadingP2POptionUID = (jsonData) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/json-data`, jsonData);
}

export const autoP2PService = {
  getAutoP2PPayments,
  updateAutoP2PPayments,

  getAutoP2PPaymentStats,

  getLoadingP2POptionUID,
  updateLoadingP2POptionUID
};
