import * as moment from 'moment'

export const getTimeNow = () => {
    return moment().format("DD/MM/YYYY HH:mm:ss")
}

export const formatTime = (time, str) => {
    if (!time)
        return "";
    
    if (str)
        return moment(time).format(str);

    return moment(time).format('DD/MM/YYYY HH:mm:ss')
}

export const getDiffMinutesFromNow = (time, isDuration = false) => {
    var now = moment(new Date());
    var duration = moment.duration(now.diff(time));
    var minutes = Math.floor(duration.asMinutes());

    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    var str = '';

    if (hours == 1)
        str = `${hours} hour `;
    else if (hours > 1)
        str = `${hours} hours `;

    str += `${minutes} ${minutes < 2? 'minute': 'minutes'}`;
    str += isDuration ? '': ' ago';

    return str;
}

export const getDiffSeconds = (time1, time2) => {
    var time1 = moment(time1);
    var time2 = moment(time2);
    var duration = moment.duration(time2.diff(time1));
    var seconds = Math.floor(duration.asSeconds());

    var minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    var str = '';

    if (minutes >= 1)
        str = `${minutes} phút `;

    str += `${seconds} giây`;

    return str;
}

export const getDiffSecondsFromNow = (time, isDuration) => {
    var now = moment(new Date());
    var duration = moment.duration(now.diff(time));
    var seconds = Math.floor(duration.asSeconds());

    var minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    var str = '';

    if (minutes == 1)
        str = `${minutes} phút `;
    else if (minutes > 1)
        str = `${minutes} phút `;

    str += `${seconds} ${seconds < 2? 'giây': 'giây'}`;
    str += isDuration ? '': ' trước';

    return str;
}

export const getDiffMinutes = (fromTime, finishTime, isDuration = false) => {
    var now = moment(finishTime);
    var duration = moment.duration(now.diff(fromTime));
    var minutes = Math.floor(duration.asMinutes());

    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    var str = '';

    if (hours == 1)
        str = `${hours} hour `;
    else if (hours > 1)
        str = `${hours} hours `;

    str += `${minutes} ${minutes < 2? 'minute': 'minutes'}`;
    str += isDuration ? '': ' ago';

    return str;
}

export const shortenText = (str, n) => {
    if (str) {
      if (str.length > n)
        return str.slice(0, n) + "...";
      
      return str;
    }

    return '';
}