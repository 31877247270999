import { TYPES } from "./types";
import { autoP2PService } from "../services"; 
import { setFlag } from "./miscAction";
import { CONFIGS } from "../configs";

export const getAutoP2PPayments = (query) => async (dispatch) => {
  try {
    console.log("@@@ getAutoP2PPayments", query);
    const result = await autoP2PService.getAutoP2PPayments(query);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_ALL_AUTOP2P_PAYMENTS_SUCCESS,
        payload: { data, stat: query.stat, statType: query.statType }
      });

      // dispatch(setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, true))
    } else {
      dispatch({
        type: TYPES.ACTION_GET_ALL_AUTOP2P_PAYMENTS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const updateAutoP2PPayment = (payment, getLatestData) => async (dispatch) => {

  try {
    const result = await autoP2PService.updateAutoP2PPayments([payment]);
    const data = result.data.message;

    console.log("@@@ updateAutoP2PPayment", data);
    
    if (data) {
      if (getLatestData) {
        await dispatch(getAutoP2PPayments({ paymentType: payment.type }));
      } else {
        dispatch({
          type: TYPES.ACTION_UPDATE_AUTOP2P_PAYMENT_SUCCESS,
          payload: { payment: data.result[0] }
        });
      }
    } else {
      dispatch({
        type: TYPES.ACTION_UPDATE_AUTOP2P_PAYMENT_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const getAutoP2PPaymentStats = (query) => async (dispatch) => {
  try {
    console.log("@@@ getAutoP2PPaymentStats", query);
    const result = await autoP2PService.getAutoP2PPaymentStats(query);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_ALL_AUTOP2P_PAYMENT_STATS_SUCCESS,
        payload: { data, type: query.type }
      });

      // dispatch(setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, true))
    } else {
      dispatch({
        type: TYPES.ACTION_GET_ALL_AUTOP2P_PAYMENT_STATS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const getLoadingP2POptionUID = (uid) => async (dispatch) => {
  try {
    const result = await autoP2PService.getLoadingP2POptionUID(uid);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_LOADING_P2P_OPTION_UID_SUCCESS,
        payload: { uid, value: data.value }
      });

      // dispatch(setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, true))
    } else {
      dispatch({
        type: TYPES.ACTION_GET_LOADING_P2P_OPTION_UID_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const updateLoadingP2POptionUID = (uid, value) => async (dispatch) => {
  try {
    const jsonData = {
      key: `AUTOP2P_LOADING_P2P_OPTION_${uid}`, value
    }
    const result = await autoP2PService.updateLoadingP2POptionUID([jsonData]);
    const data = result.data.message;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_LOADING_P2P_OPTION_UID_SUCCESS,
        payload: { uid, value: jsonData.value }
      });

      // dispatch(setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, true))
    } else {
      dispatch({
        type: TYPES.ACTION_UPDATE_LOADING_P2P_OPTION_UID_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const autoP2PAction = {
  getAutoP2PPayments,
  updateAutoP2PPayment,

  getLoadingP2POptionUID,
  updateLoadingP2POptionUID
};
