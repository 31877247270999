// https://github.com/vietqr/vietqr-node

exports.BANKS_LIST = [
    {
        "id": 17,
        "name": "Ngân hàng TMCP Công thương Việt Nam",
        "code": "ICB",
        "bin": "970415",
        "shortName": "VietinBank",
        "logo": "https://api.vietqr.io/img/ICB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "VietinBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "ICBVVNVX",
        "tags": "Vietinbank, Cong Thuong, ICB",
        "displayName": "ICB, VietinBank, Ngân hàng TMCP Công thương Việt Nam"
    },
    {
        "id": 43,
        "name": "Ngân hàng TMCP Ngoại Thương Việt Nam",
        "code": "VCB",
        "bin": "970436",
        "shortName": "Vietcombank",
        "logo": "https://api.vietqr.io/img/VCB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "Vietcombank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "BFTVVNVX",
        "tags": "Vietcombank, VCB, Ngoai Thuong Viet Nam",
        "displayName": "VCB, Vietcombank, Ngân hàng TMCP Ngoại Thương Việt Nam"
    },
    {
        "id": 4,
        "name": "Ngân hàng TMCP Đầu tư và Phát triển Việt Nam",
        "code": "BIDV",
        "bin": "970418",
        "shortName": "BIDV",
        "logo": "https://api.vietqr.io/img/BIDV.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "BIDV",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "BIDVVNVX",
        "tags": "BIDV",
        "displayName": "BIDV, Ngân hàng TMCP Đầu tư và Phát triển Việt Nam"
    },
    {
        "id": 42,
        "name": "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam",
        "code": "VBA",
        "bin": "970405",
        "shortName": "Agribank",
        "logo": "https://api.vietqr.io/img/VBA.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "Agribank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "VBAAVNVX",
        "tags": "Agribank, VBARD, Nong Nghiep",
        "displayName": "VBA, Agribank, Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam"
    },
    {
        "id": 26,
        "name": "Ngân hàng TMCP Phương Đông",
        "code": "OCB",
        "bin": "970448",
        "shortName": "OCB",
        "logo": "https://api.vietqr.io/img/OCB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "OCB",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "ORCOVNVX",
        "tags": "Oricombank, OCB, PhuongDong Bank",
        "displayName": "OCB, Oricombank, Ngân hàng TMCP Phương Đông"
    },
    {
        "id": 21,
        "name": "Ngân hàng TMCP Quân đội",
        "code": "MB",
        "bin": "970422",
        "shortName": "MBBank",
        "logo": "https://api.vietqr.io/img/MB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "MBBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "MSCBVNVX",
        "tags": "MB, MB Bank, Quan Doi",
        "displayName": "MB, MBBank, Ngân hàng TMCP Quân đội"
    },
    {
        "id": 38,
        "name": "Ngân hàng TMCP Kỹ thương Việt Nam",
        "code": "TCB",
        "bin": "970407",
        "shortName": "Techcombank",
        "logo": "https://api.vietqr.io/img/TCB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "Techcombank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "VTCBVNVX",
        "tags": "Techcombank, TCB",
        "displayName": "TCB, Techcombank, Ngân hàng TMCP Kỹ thương Việt Nam"
    },
    {
        "id": 2,
        "name": "Ngân hàng TMCP Á Châu",
        "code": "ACB",
        "bin": "970416",
        "shortName": "ACB",
        "logo": "https://api.vietqr.io/img/ACB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "ACB",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "ASCBVNVX",
        "tags": "ACB",
        "displayName": "ACB, Ngân hàng TMCP Á Châu"
    },
    {
        "id": 47,
        "name": "Ngân hàng TMCP Việt Nam Thịnh Vượng",
        "code": "VPB",
        "bin": "970432",
        "shortName": "VPBank",
        "logo": "https://api.vietqr.io/img/VPB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "VPBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "VPBKVNVX",
        "tags": "VPBank, vp bank",
        "displayName": "VPB, VPBank, Ngân hàng TMCP Việt Nam Thịnh Vượng"
    },
    {
        "id": 39,
        "name": "Ngân hàng TMCP Tiên Phong",
        "code": "TPB",
        "bin": "970423",
        "shortName": "TPBank",
        "logo": "https://api.vietqr.io/img/TPB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "TPBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "TPBVVNVX",
        "tags": "TPBank, TP Bank, Tien Phong Bank, TienPhong Bank, TienPhong, Tien Phong",
        "displayName": "TPB, TPBank, Ngân hàng TMCP Tiên Phong"
    },
    {
        "id": 36,
        "name": "Ngân hàng TMCP Sài Gòn Thương Tín",
        "code": "STB",
        "bin": "970403",
        "shortName": "Sacombank",
        "logo": "https://api.vietqr.io/img/STB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "Sacombank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "SGTTVNVX",
        "tags": "Sacombank, Saigon Thuong Tin",
        "displayName": "STB, Sacombank, Ngân hàng TMCP Sài Gòn Thương Tín"
    },
    {
        "id": 12,
        "name": "Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh",
        "code": "HDB",
        "bin": "970437",
        "shortName": "HDBank",
        "logo": "https://api.vietqr.io/img/HDB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "HDBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "HDBCVNVX",
        "tags": "HDBank",
        "displayName": "HDB, HDBank, Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh"
    },
    {
        "id": 44,
        "name": "Ngân hàng TMCP Bản Việt",
        "code": "VCCB",
        "bin": "970454",
        "shortName": "VietCapitalBank",
        "logo": "https://api.vietqr.io/img/VCCB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "VietCapitalBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "VCBCVNVX",
        "tags": "VietCapital Bank",
        "displayName": "VCCB, VietCapitalBank, Ngân hàng TMCP Bản Việt"
    },
    {
        "id": 31,
        "name": "Ngân hàng TMCP Sài Gòn",
        "code": "SCB",
        "bin": "970429",
        "shortName": "SCB",
        "logo": "https://api.vietqr.io/img/SCB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "SCB",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "SACLVNVX",
        "tags": "SCB",
        "displayName": "SCB, Ngân hàng TMCP Sài Gòn"
    },
    {
        "id": 45,
        "name": "Ngân hàng TMCP Quốc tế Việt Nam",
        "code": "VIB",
        "bin": "970441",
        "shortName": "VIB",
        "logo": "https://api.vietqr.io/img/VIB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "VIB",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "VNIBVNVX",
        "tags": "VIBank, VIB",
        "displayName": "VIB, Ngân hàng TMCP Quốc tế Việt Nam"
    },
    {
        "id": 35,
        "name": "Ngân hàng TMCP Sài Gòn - Hà Nội",
        "code": "SHB",
        "bin": "970443",
        "shortName": "SHB",
        "logo": "https://api.vietqr.io/img/SHB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "SHB",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "SHBAVNVX",
        "tags": "SHB",
        "displayName": "SHB, Ngân hàng TMCP Sài Gòn - Hà Nội"
    },
    {
        "id": 10,
        "name": "Ngân hàng TMCP Xuất Nhập khẩu Việt Nam",
        "code": "EIB",
        "bin": "970431",
        "shortName": "Eximbank",
        "logo": "https://api.vietqr.io/img/EIB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "Eximbank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "EBVIVNVX",
        "tags": "Eximbank, EIB",
        "displayName": "EIB, Eximbank, Ngân hàng TMCP Xuất Nhập khẩu Việt Nam"
    },
    {
        "id": 22,
        "name": "Ngân hàng TMCP Hàng Hải",
        "code": "MSB",
        "bin": "970426",
        "shortName": "MSB",
        "logo": "https://api.vietqr.io/img/MSB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "MSB",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "MCOBVNVX",
        "tags": "Maritime Bank, MSB, Ngân hàng TMCP Hàng Hải",
        "displayName": "MSB, Ngân hàng TMCP Hàng Hải"
    },
    {
        "id": 53,
        "name": "TMCP Việt Nam Thịnh Vượng - Ngân hàng số CAKE by VPBank",
        "code": "CAKE",
        "bin": "546034",
        "shortName": "CAKE",
        "logo": "https://api.vietqr.io/img/CAKE.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "CAKE",
        "support": 3,
        "isTransfer": 1,
        "swift_code": null,
        "tags": "CAKE, CAKE BANK",
        "displayName": "CAKE, TMCP Việt Nam Thịnh Vượng - Ngân hàng số CAKE by VPBank"
    },
    {
        "id": 54,
        "name": "TMCP Việt Nam Thịnh Vượng - Ngân hàng số Ubank by VPBank",
        "code": "Ubank",
        "bin": "546035",
        "shortName": "Ubank",
        "logo": "https://api.vietqr.io/img/UBANK.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "Ubank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": null,
        "tags": "",
        "displayName": "Ubank, TMCP Việt Nam Thịnh Vượng - Ngân hàng số Ubank by VPBank"
    },
    {
        "id": 58,
        "name": "Ngân hàng số Timo by Ban Viet Bank (Timo by Ban Viet Bank)",
        "code": "TIMO",
        "bin": "963388",
        "shortName": "Timo",
        "logo": "https://vietqr.net/portal-service/resources/icons/TIMO.png",
        "transferSupported": 1,
        "lookupSupported": 0,
        "short_name": "Timo",
        "support": 0,
        "isTransfer": 1,
        "swift_code": null,
        "tags": "",
        "displayName": "TIMO, Timo, Ngân hàng số Timo by Ban Viet Bank (Timo by Ban Viet Bank)"
    },
    {
        "id": 57,
        "name": "Tổng Công ty Dịch vụ số Viettel - Chi nhánh tập đoàn công nghiệp viễn thông Quân Đội",
        "code": "VTLMONEY",
        "bin": "971005",
        "shortName": "ViettelMoney",
        "logo": "https://api.vietqr.io/img/VIETTELMONEY.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "ViettelMoney",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "VTLMONEY, ViettelMoney, Tổng Công ty Dịch vụ số Viettel - Chi nhánh tập đoàn công nghiệp viễn thông Quân Đội"
    },
    {
        "id": 56,
        "name": "VNPT Money",
        "code": "VNPTMONEY",
        "bin": "971011",
        "shortName": "VNPTMoney",
        "logo": "https://api.vietqr.io/img/VNPTMONEY.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "VNPTMoney",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "VNPTMONEY, VNPT Money"
    },
    {
        "id": 34,
        "name": "Ngân hàng TMCP Sài Gòn Công Thương",
        "code": "SGICB",
        "bin": "970400",
        "shortName": "SaigonBank",
        "logo": "https://api.vietqr.io/img/SGICB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "SaigonBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "SBITVNVX",
        "tags": "Saigonbank",
        "displayName": "SGICB, SaigonBank, Ngân hàng TMCP Sài Gòn Công Thương"
    },
    {
        "id": 3,
        "name": "Ngân hàng TMCP Bắc Á",
        "code": "BAB",
        "bin": "970409",
        "shortName": "BacABank",
        "logo": "https://api.vietqr.io/img/BAB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "BacABank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "NASCVNVX",
        "tags": "NASBank, NASB",
        "displayName": "BAB, BacABank, Ngân hàng TMCP Bắc Á"
    },
    {
        "id": 30,
        "name": "Ngân hàng TMCP Đại Chúng Việt Nam",
        "code": "PVCB",
        "bin": "970412",
        "shortName": "PVcomBank",
        "logo": "https://api.vietqr.io/img/PVCB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "PVcomBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "WBVNVNVX",
        "tags": "PVcombank",
        "displayName": "PVCB, PVcomBank, Ngân hàng TMCP Đại Chúng Việt Nam"
    },
    {
        "id": 27,
        "name": "Ngân hàng Thương mại TNHH MTV Đại Dương",
        "code": "Oceanbank",
        "bin": "970414",
        "shortName": "Oceanbank",
        "logo": "https://api.vietqr.io/img/OCEANBANK.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "Oceanbank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "OCBKUS3M",
        "tags": "Ocean Bank, Ocean",
        "displayName": "Oceanbank, Ngân hàng Thương mại TNHH MTV Đại Dương"
    },
    {
        "id": 24,
        "name": "Ngân hàng TMCP Quốc Dân",
        "code": "NCB",
        "bin": "970419",
        "shortName": "NCB",
        "logo": "https://api.vietqr.io/img/NCB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "NCB",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "NVBAVNVX",
        "tags": "NCB, Quoc Dan",
        "displayName": "NCB, Ngân hàng TMCP Quốc Dân"
    },
    {
        "id": 37,
        "name": "Ngân hàng TNHH MTV Shinhan Việt Nam",
        "code": "SHBVN",
        "bin": "970424",
        "shortName": "ShinhanBank",
        "logo": "https://api.vietqr.io/img/SHBVN.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "ShinhanBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "SHBKVNVX",
        "tags": "Shinhan Bank",
        "displayName": "SHBVN, ShinhanBank, Ngân hàng TNHH MTV Shinhan Việt Nam"
    },
    {
        "id": 1,
        "name": "Ngân hàng TMCP An Bình",
        "code": "ABB",
        "bin": "970425",
        "shortName": "ABBANK",
        "logo": "https://api.vietqr.io/img/ABB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "ABBANK",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "ABBKVNVX",
        "tags": "ABB, ABBANK, Ngân hàng TMCP An Bình",
        "displayName": "ABB, ABBANK, Ngân hàng TMCP An Bình"
    },
    {
        "id": 41,
        "name": "Ngân hàng TMCP Việt Á",
        "code": "VAB",
        "bin": "970427",
        "shortName": "VietABank",
        "logo": "https://api.vietqr.io/img/VAB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "VietABank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "VNACVNVX",
        "tags": "VietA Bank, VAB",
        "displayName": "VAB, VietABank, Ngân hàng TMCP Việt Á"
    },
    {
        "id": 23,
        "name": "Ngân hàng TMCP Nam Á",
        "code": "NAB",
        "bin": "970428",
        "shortName": "NamABank",
        "logo": "https://api.vietqr.io/img/NAB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "NamABank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "NAMAVNVX",
        "tags": "Nam A Bank, NAB",
        "displayName": "NAB, NamABank, Ngân hàng TMCP Nam Á"
    },
    {
        "id": 29,
        "name": "Ngân hàng TMCP Xăng dầu Petrolimex",
        "code": "PGB",
        "bin": "970430",
        "shortName": "PGBank",
        "logo": "https://api.vietqr.io/img/PGB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "PGBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "PGBLVNVX",
        "tags": "PG Bank, PG",
        "displayName": "PGB, PGBank, Ngân hàng TMCP Xăng dầu Petrolimex"
    },
    {
        "id": 46,
        "name": "Ngân hàng TMCP Việt Nam Thương Tín",
        "code": "VIETBANK",
        "bin": "970433",
        "shortName": "VietBank",
        "logo": "https://api.vietqr.io/img/VIETBANK.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "VietBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "VNTTVNVX",
        "tags": "Vietbank, Thuong Tin",
        "displayName": "VIETBANK, VietBank, Ngân hàng TMCP Việt Nam Thương Tín"
    },
    {
        "id": 5,
        "name": "Ngân hàng TMCP Bảo Việt",
        "code": "BVB",
        "bin": "970438",
        "shortName": "BaoVietBank",
        "logo": "https://api.vietqr.io/img/BVB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "BaoVietBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "BVBVVNVX",
        "tags": "Baoviet Bank, Baoviet",
        "displayName": "BVB, BaoVietBank, Ngân hàng TMCP Bảo Việt"
    },
    {
        "id": 33,
        "name": "Ngân hàng TMCP Đông Nam Á",
        "code": "SEAB",
        "bin": "970440",
        "shortName": "SeABank",
        "logo": "https://api.vietqr.io/img/SEAB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "SeABank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "SEAVVNVX",
        "tags": "SeABank",
        "displayName": "SEAB, SeABank, Ngân hàng TMCP Đông Nam Á"
    },
    {
        "id": 52,
        "name": "Ngân hàng Hợp tác xã Việt Nam",
        "code": "COOPBANK",
        "bin": "970446",
        "shortName": "COOPBANK",
        "logo": "https://api.vietqr.io/img/COOPBANK.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "COOPBANK",
        "support": 3,
        "isTransfer": 1,
        "swift_code": null,
        "tags": "",
        "displayName": "COOPBANK, Ngân hàng Hợp tác xã Việt Nam"
    },
    {
        "id": 20,
        "name": "Ngân hàng TMCP Lộc Phát Việt Nam",
        "code": "LPB",
        "bin": "970449",
        "shortName": "LPBank",
        "logo": "https://api.vietqr.io/img/LPB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "LPBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "LVBKVNVX",
        "tags": "Lienvietbank,  LPB, Lien Viet",
        "displayName": "LPB, LPBank, Ngân hàng TMCP Lộc Phát Việt Nam"
    },
    {
        "id": 19,
        "name": "Ngân hàng TMCP Kiên Long",
        "code": "KLB",
        "bin": "970452",
        "shortName": "KienLongBank",
        "logo": "https://api.vietqr.io/img/KLB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "KienLongBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "KLBKVNVX",
        "tags": "Kienlongbank",
        "displayName": "KLB, KienLongBank, Ngân hàng TMCP Kiên Long"
    },
    {
        "id": 55,
        "name": "Ngân hàng Đại chúng TNHH Kasikornbank",
        "code": "KBank",
        "bin": "668888",
        "shortName": "KBank",
        "logo": "https://api.vietqr.io/img/KBANK.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "KBank",
        "support": 3,
        "isTransfer": 1,
        "swift_code": "KASIVNVX",
        "tags": "",
        "displayName": "KBank, Ngân hàng Đại chúng TNHH Kasikornbank"
    },
    {
        "id": 50,
        "name": "Ngân hàng Kookmin - Chi nhánh Hà Nội",
        "code": "KBHN",
        "bin": "970462",
        "shortName": "KookminHN",
        "logo": "https://api.vietqr.io/img/KBHN.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "KookminHN",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "KBHN, KookminHN, Ngân hàng Kookmin - Chi nhánh Hà Nội"
    },
    {
        "id": 60,
        "name": "Ngân hàng KEB Hana – Chi nhánh Thành phố Hồ Chí Minh",
        "code": "KEBHANAHCM",
        "bin": "970466",
        "shortName": "KEBHanaHCM",
        "logo": "https://api.vietqr.io/img/KEBHANAHCM.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "KEBHanaHCM",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "KEBHANAHCM, Ngân hàng KEB Hana – Chi nhánh Thành phố Hồ Chí Minh"
    },
    {
        "id": 61,
        "name": "Ngân hàng KEB Hana – Chi nhánh Hà Nội",
        "code": "KEBHANAHN",
        "bin": "970467",
        "shortName": "KEBHANAHN",
        "logo": "https://api.vietqr.io/img/KEBHANAHN.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "KEBHANAHN",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "KEBHANAHN, Ngân hàng KEB Hana – Chi nhánh Hà Nội"
    },
    {
        "id": 62,
        "name": "Công ty Tài chính TNHH MTV Mirae Asset (Việt Nam) ",
        "code": "MAFC",
        "bin": "977777",
        "shortName": "MAFC",
        "logo": "https://api.vietqr.io/img/MAFC.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "MAFC",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "MAFC, Công ty Tài chính TNHH MTV Mirae Asset (Việt Nam) "
    },
    {
        "id": 59,
        "name": "Ngân hàng Citibank, N.A. - Chi nhánh Hà Nội",
        "code": "CITIBANK",
        "bin": "533948",
        "shortName": "Citibank",
        "logo": "https://api.vietqr.io/img/CITIBANK.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "Citibank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "CITIBANK, Citibank, Ngân hàng Citibank, N.A. - Chi nhánh Hà Nội"
    },
    {
        "id": 51,
        "name": "Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh",
        "code": "KBHCM",
        "bin": "970463",
        "shortName": "KookminHCM",
        "logo": "https://api.vietqr.io/img/KBHCM.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "KookminHCM",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "KBHCM, KookminHCM, Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh"
    },
    {
        "id": 63,
        "name": "Ngân hàng Chính sách Xã hội",
        "code": "VBSP",
        "bin": "999888",
        "shortName": "VBSP",
        "logo": "https://api.vietqr.io/img/VBSP.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "VBSP",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "chinh sach xa hoi",
        "displayName": "VBSP, Ngân hàng Chính sách Xã hội"
    },
    {
        "id": 49,
        "name": "Ngân hàng TNHH MTV Woori Việt Nam",
        "code": "WVN",
        "bin": "970457",
        "shortName": "Woori",
        "logo": "https://api.vietqr.io/img/WVN.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "Woori",
        "support": 0,
        "isTransfer": 1,
        "swift_code": null,
        "tags": "WHHN",
        "displayName": "WVN, Woori, Ngân hàng TNHH MTV Woori Việt Nam"
    },
    {
        "id": 48,
        "name": "Ngân hàng Liên doanh Việt - Nga",
        "code": "VRB",
        "bin": "970421",
        "shortName": "VRB",
        "logo": "https://api.vietqr.io/img/VRB.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "VRB",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "VRB",
        "displayName": "VRB, Ngân hàng Liên doanh Việt - Nga"
    },
    {
        "id": 40,
        "name": "Ngân hàng United Overseas - Chi nhánh TP. Hồ Chí Minh",
        "code": "UOB",
        "bin": "970458",
        "shortName": "UnitedOverseas",
        "logo": "https://api.vietqr.io/img/UOB.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "UnitedOverseas",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "UOB",
        "displayName": "UOB, UnitedOverseas, Ngân hàng United Overseas - Chi nhánh TP. Hồ Chí Minh"
    },
    {
        "id": 32,
        "name": "Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam",
        "code": "SCVN",
        "bin": "970410",
        "shortName": "StandardChartered",
        "logo": "https://api.vietqr.io/img/SCVN.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "StandardChartered",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "SCBLVNVX",
        "tags": "",
        "displayName": "SCVN, StandardChartered, Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam"
    },
    {
        "id": 28,
        "name": "Ngân hàng TNHH MTV Public Việt Nam",
        "code": "PBVN",
        "bin": "970439",
        "shortName": "PublicBank",
        "logo": "https://api.vietqr.io/img/PBVN.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "PublicBank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "VIDPVNVX",
        "tags": "VID public",
        "displayName": "PBVN, PublicBank, Ngân hàng TNHH MTV Public Việt Nam"
    },
    {
        "id": 25,
        "name": "Ngân hàng Nonghyup - Chi nhánh Hà Nội",
        "code": "NHB HN",
        "bin": "801011",
        "shortName": "Nonghyup",
        "logo": "https://api.vietqr.io/img/NHB.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "Nonghyup",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "NHB HN, Nonghyup, Ngân hàng Nonghyup - Chi nhánh Hà Nội"
    },
    {
        "id": 18,
        "name": "Ngân hàng TNHH Indovina",
        "code": "IVB",
        "bin": "970434",
        "shortName": "IndovinaBank",
        "logo": "https://api.vietqr.io/img/IVB.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "IndovinaBank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "IVB",
        "displayName": "IVB, IndovinaBank, Ngân hàng TNHH Indovina"
    },
    {
        "id": 16,
        "name": "Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh TP. Hồ Chí Minh",
        "code": "IBK - HCM",
        "bin": "970456",
        "shortName": "IBKHCM",
        "logo": "https://api.vietqr.io/img/IBK.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "IBKHCM",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "IBK - HCM, IBKHCM, Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh TP. Hồ Chí Minh"
    },
    {
        "id": 15,
        "name": "Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh Hà Nội",
        "code": "IBK - HN",
        "bin": "970455",
        "shortName": "IBKHN",
        "logo": "https://api.vietqr.io/img/IBK.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "IBKHN",
        "support": 0,
        "isTransfer": 0,
        "swift_code": null,
        "tags": "",
        "displayName": "IBK - HN, IBKHN, Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh Hà Nội"
    },
    {
        "id": 14,
        "name": "Ngân hàng TNHH MTV HSBC (Việt Nam)",
        "code": "HSBC",
        "bin": "458761",
        "shortName": "HSBC",
        "logo": "https://api.vietqr.io/img/HSBC.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "HSBC",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "HSBCVNVX",
        "tags": "",
        "displayName": "HSBC, Ngân hàng TNHH MTV HSBC (Việt Nam)"
    },
    {
        "id": 13,
        "name": "Ngân hàng TNHH MTV Hong Leong Việt Nam",
        "code": "HLBVN",
        "bin": "970442",
        "shortName": "HongLeong",
        "logo": "https://api.vietqr.io/img/HLBVN.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "HongLeong",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "HLBBVNVX",
        "tags": "HLO",
        "displayName": "HLBVN, HongLeong, Ngân hàng TNHH MTV Hong Leong Việt Nam"
    },
    {
        "id": 11,
        "name": "Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu",
        "code": "GPB",
        "bin": "970408",
        "shortName": "GPBank",
        "logo": "https://api.vietqr.io/img/GPB.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "GPBank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "GBNKVNVX",
        "tags": "GP, GP Bank",
        "displayName": "GPB, GPBank, Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu"
    },
    {
        "id": 9,
        "name": "Ngân hàng TMCP Đông Á",
        "code": "DOB",
        "bin": "970406",
        "shortName": "DongABank",
        "logo": "https://api.vietqr.io/img/DOB.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "DongABank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "EACBVNVX",
        "tags": "Dong A Bank, DAB",
        "displayName": "DOB, DongABank, Ngân hàng TMCP Đông Á"
    },
    {
        "id": 8,
        "name": "DBS Bank Ltd - Chi nhánh Thành phố Hồ Chí Minh",
        "code": "DBS",
        "bin": "796500",
        "shortName": "DBSBank",
        "logo": "https://api.vietqr.io/img/DBS.png",
        "transferSupported": 0,
        "lookupSupported": 0,
        "short_name": "DBSBank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "DBSSVNVX",
        "tags": "",
        "displayName": "DBS, DBSBank, DBS Bank Ltd - Chi nhánh Thành phố Hồ Chí Minh"
    },
    {
        "id": 7,
        "name": "Ngân hàng TNHH MTV CIMB Việt Nam",
        "code": "CIMB",
        "bin": "422589",
        "shortName": "CIMB",
        "logo": "https://api.vietqr.io/img/CIMB.png",
        "transferSupported": 1,
        "lookupSupported": 1,
        "short_name": "CIMB",
        "support": 0,
        "isTransfer": 1,
        "swift_code": "CIBBVNVN",
        "tags": "",
        "displayName": "CIMB, Ngân hàng TNHH MTV CIMB Việt Nam"
    },
    {
        "id": 6,
        "name": "Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam",
        "code": "CBB",
        "bin": "970444",
        "shortName": "CBBank",
        "logo": "https://api.vietqr.io/img/CBB.png",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "CBBank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "GTBAVNVX",
        "tags": "",
        "displayName": "CBB, CBBank, Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam"
    },
    {
        "name": "Tổng Công ty Dịch vụ số Viettel - Chi nhánh tập đoàn công nghiệp viễn thông Quân Đội",
        "code": "VTDS",
        "bin": "970460",
        "shortName": "Viettel Money",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Viettel Money",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "VTDS,Viettel Money,Tổng Công ty Dịch vụ số Viettel - Chi nhánh tập đoàn công nghiệp viễn thông Quân Đội",
        "displayName": "VTDS,Viettel Money,Tổng Công ty Dịch vụ số Viettel - Chi nhánh tập đoàn công nghiệp viễn thông Quân Đội"
    },
    {
        "name": "Ngân hàng Đại chúng TNHH Kasikornbank",
        "code": "KASIK",
        "bin": "970459",
        "shortName": "KasikornBank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "KasikornBank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "KASIK,KasikornBank,Ngân hàng Đại chúng TNHH Kasikornbank",
        "displayName": "KASIK,KasikornBank,Ngân hàng Đại chúng TNHH Kasikornbank"
    },
    {
        "name": "Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam",
        "code": "SCVN",
        "bin": "970461",
        "shortName": "Standard Chartered Vietnam",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Standard Chartered Vietnam",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "SCVN,Standard Chartered Vietnam,Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam",
        "displayName": "SCVN,Standard Chartered Vietnam,Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam"
    },
    {
        "name": "Ngân hàng Nonghyup - Chi nhánh Hà Nội",
        "code": "NHB",
        "bin": "970465",
        "shortName": "Nonghyup Bank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Nonghyup Bank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "NHB,Nonghyup Bank,Ngân hàng Nonghyup - Chi nhánh Hà Nội",
        "displayName": "NHB,Nonghyup Bank,Ngân hàng Nonghyup - Chi nhánh Hà Nội"
    },
    {
        "name": "Ngân hàng TNHH Indovina",
        "code": "IVB",
        "bin": "970464",
        "shortName": "Indovina Bank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Indovina Bank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "IVB,Indovina Bank,Ngân hàng TNHH Indovina",
        "displayName": "IVB,Indovina Bank,Ngân hàng TNHH Indovina"
    },
    {
        "name": "Ngân hàng Liên doanh Việt - Nga",
        "code": "VRB",
        "bin": "970468",
        "shortName": "VRB",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "VRB",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "VRB,VRB,Ngân hàng Liên doanh Việt - Nga",
        "displayName": "VRB,VRB,Ngân hàng Liên doanh Việt - Nga"
    },
    {
        "name": "Ngân hàng TNHH MTV Woori Việt Nam",
        "code": "WRB",
        "bin": "970453",
        "shortName": "Woori Bank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Woori Bank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "WRB,Woori Bank,Ngân hàng TNHH MTV Woori Việt Nam",
        "displayName": "WRB,Woori Bank,Ngân hàng TNHH MTV Woori Việt Nam"
    },
    {
        "name": "Ngân hàng Kookmin - Chi nhánh Hà Nội",
        "code": "KBHB",
        "bin": "970469",
        "shortName": "Kookmin Bank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Kookmin Bank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "KBHB,Kookmin Bank,Ngân hàng Kookmin - Chi nhánh Hà Nội",
        "displayName": "KBHB,Kookmin Bank,Ngân hàng Kookmin - Chi nhánh Hà Nội"
    },
    {
        "name": "Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh",
        "code": "KBHC",
        "bin": "970470",
        "shortName": "Kookmin Bank HCMC",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Kookmin Bank HCMC",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "KBHC,Kookmin Bank HCMC,Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh",
        "displayName": "KBHC,Kookmin Bank HCMC,Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh"
    },
    {
        "name": "Ngân hàng TNHH MTV Hong Leong Việt Nam",
        "code": "HLVB",
        "bin": "970471",
        "shortName": "Hong Leong Bank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Hong Leong Bank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "HLVB,Hong Leong Bank,Ngân hàng TNHH MTV Hong Leong Việt Nam",
        "displayName": "HLVB,Hong Leong Bank,Ngân hàng TNHH MTV Hong Leong Việt Nam"
    },
    {
        "name": "Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu",
        "code": "GPB",
        "bin": "970447",
        "shortName": "GPBank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "GPBank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "GPB,GPBank,Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu",
        "displayName": "GPB,GPBank,Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu"
    },
    {
        "name": "Ngân hàng TNHH MTV CIMB Việt Nam",
        "code": "CIMB",
        "bin": "970472",
        "shortName": "CIMB",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "CIMB",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "CIMB,CIMB,Ngân hàng TNHH MTV CIMB Việt Nam",
        "displayName": "CIMB,CIMB,Ngân hàng TNHH MTV CIMB Việt Nam"
    },
    {
        "name": "Ngân hàng Citibank, N.A. - Chi nhánh Hà Nội",
        "code": "CITI",
        "bin": "970473",
        "shortName": "Citibank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Citibank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "CITI,Citibank,Ngân hàng Citibank, N.A. - Chi nhánh Hà Nội",
        "displayName": "CITI,Citibank,Ngân hàng Citibank, N.A. - Chi nhánh Hà Nội"
    },
    {
        "name": "Ngân hàng KEB Hana – Chi nhánh Thành phố Hồ Chí Minh",
        "code": "HANA",
        "bin": "970474",
        "shortName": "KEB Hana Bank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "KEB Hana Bank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "HANA,KEB Hana Bank,Ngân hàng KEB Hana – Chi nhánh Thành phố Hồ Chí Minh",
        "displayName": "HANA,KEB Hana Bank,Ngân hàng KEB Hana – Chi nhánh Thành phố Hồ Chí Minh"
    },
    {
        "name": "Ngân hàng KEB Hana – Chi nhánh Hà Nội",
        "code": "HANA_HN",
        "bin": "970475",
        "shortName": "KEB Hana Bank Hanoi",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "KEB Hana Bank Hanoi",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "HANA_HN,KEB Hana Bank Hanoi,Ngân hàng KEB Hana – Chi nhánh Hà Nội",
        "displayName": "HANA_HN,KEB Hana Bank Hanoi,Ngân hàng KEB Hana – Chi nhánh Hà Nội"
    },
    {
        "name": "Công ty Tài chính TNHH MTV Mirae Asset (Việt Nam)",
        "code": "MAS",
        "bin": "970476",
        "shortName": "Mirae Asset Finance",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Mirae Asset Finance",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "MAS,Mirae Asset Finance,Công ty Tài chính TNHH MTV Mirae Asset (Việt Nam)",
        "displayName": "MAS,Mirae Asset Finance,Công ty Tài chính TNHH MTV Mirae Asset (Việt Nam)"
    },
    {
        "name": "Ngân hàng Chính sách Xã hội",
        "code": "VBSP",
        "bin": "970477",
        "shortName": "VBSP",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "VBSP",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "VBSP,VBSP,Ngân hàng Chính sách Xã hội",
        "displayName": "VBSP,VBSP,Ngân hàng Chính sách Xã hội"
    },
    {
        "name": "Ngân hàng TMCP LioBank",
        "code": "LIO",
        "bin": "970478",
        "shortName": "LioBank",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "LioBank",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "LIO,LioBank,Ngân hàng TMCP LioBank",
        "displayName": "LIO,LioBank,Ngân hàng TMCP LioBank"
    },
    {
        "name": "Ví điện tử MoMo",
        "code": "MOMO",
        "bin": "999999",
        "shortName": "MoMo",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "MoMo",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "MOMO,MoMo,Ví điện tử MoMo",
        "displayName": "MOMO,MoMo,Ví điện tử MoMo"
    },
    {
        "name": "Ví điện tử VNPAY",
        "code": "VNPAY",
        "bin": "888888",
        "shortName": "VNPAY",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "VNPAY",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "VNPAY,VNPAY,Ví điện tử VNPAY",
        "displayName": "VNPAY,VNPAY,Ví điện tử VNPAY"
    },
    {
        "name": "Ví điện tử ShopeePay",
        "code": "SHOPEE",
        "bin": "777777",
        "shortName": "ShopeePay",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "ShopeePay",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "SHOPEE,ShopeePay,Ví điện tử ShopeePay",
        "displayName": "SHOPEE,ShopeePay,Ví điện tử ShopeePay"
    },
    {
        "name": "Ví điện tử ZaloPay",
        "code": "ZALO",
        "bin": "666666",
        "shortName": "ZaloPay",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "ZaloPay",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "ZALO,ZaloPay,Ví điện tử ZaloPay",
        "displayName": "ZALO,ZaloPay,Ví điện tử ZaloPay"
    },
    {
        "name": "Ví điện tử Viettel Money",
        "code": "VTMONEY",
        "bin": "555555",
        "shortName": "Viettel Money",
        "logo": "",
        "transferSupported": 0,
        "lookupSupported": 1,
        "short_name": "Viettel Money",
        "support": 0,
        "isTransfer": 0,
        "swift_code": "",
        "tags": "VTMONEY,Viettel Money,Ví điện tử Viettel Money",
        "displayName": "VTMONEY,Viettel Money,Ví điện tử Viettel Money"
    }
  ]