import { CONFIGS } from "configs";
import { TYPES } from "../actions";
import { getLoadingP2POptionUID } from "services";

const initialState = {
  autoP2PPayments: [],
  autoP2PPaymentsLatest: {
    HOURLY: {
      BUY: [],
      SELL: []
    },
    DAILY: {
      BUY: [],
      SELL: []
    }
  },
  autoP2PPaymentStats: {
    HOURLY: {
      BUY: [],
      SELL: []
    },
    DAILY: {
      BUY: [],
      SELL: []
    }
  },
  loadingP2POptionUID: {},
  timestampGet: Date.now(),
  timestampGetStat: Date.now(),
  timestampGetLatest: Date.now()
};

export const autoP2PReducer = (state = initialState, action) => {
  switch (action.type) {

    case TYPES.ACTION_GET_ALL_AUTOP2P_PAYMENTS_SUCCESS:
      if (action.payload.stat)
        return { ...state, 
          autoP2PPaymentsLatest: {
            ...state.autoP2PPaymentsLatest,
            [action.payload.statType]: {
              [CONFIGS.AUTOP2P_PAYMENT_TYPES.BUY]: action.payload.data.result.filter(payment => payment.type == CONFIGS.AUTOP2P_PAYMENT_TYPES.BUY),
              [CONFIGS.AUTOP2P_PAYMENT_TYPES.SELL]: action.payload.data.result.filter(payment => payment.type == CONFIGS.AUTOP2P_PAYMENT_TYPES.SELL)
            }
          },
          timestampGetLatest: Date.now()
      };

      return { ...state, 
          autoP2PPayments: action.payload.data.result,
          timestampGet: Date.now()
      };

    case TYPES.ACTION_GET_ALL_AUTOP2P_PAYMENT_STATS_SUCCESS:
      return { ...state, 
          autoP2PPaymentStats: {
            ...state.autoP2PPaymentStats,
            [action.payload.type]: {
              [CONFIGS.AUTOP2P_PAYMENT_TYPES.BUY]: action.payload.data.result.filter(stat => stat.payment_type == CONFIGS.AUTOP2P_PAYMENT_TYPES.BUY),
              [CONFIGS.AUTOP2P_PAYMENT_TYPES.SELL]: action.payload.data.result.filter(stat => stat.payment_type == CONFIGS.AUTOP2P_PAYMENT_TYPES.SELL)
            }
          },
          timestampGetStat: Date.now()
      };

    case TYPES.ACTION_UPDATE_AUTOP2P_PAYMENT_SUCCESS:
      var updatedPayment = action.payload.payment;

      return { ...state, 
          autoP2PPayments: autoP2PPayments.map(payment => (
            (payment.order_id == updatedPayment.order_id)?
            updatedPayment: payment
          ))
      };

    case TYPES.ACTION_GET_LOADING_P2P_OPTION_UID_SUCCESS:
      return { ...state, 
        loadingP2POptionUID: {
          ...state.loadingP2POptionUID,
          [action.payload.uid]: action.payload.value
        }
    };
      
    default:
      return state;
  }
};
